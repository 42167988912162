import {
  addDays,
  differenceInCalendarDays,
  differenceInMinutes,
  isAfter,
  parse,
} from 'date-fns';
import { createContext, Reducer, useContext } from 'react';

import {
  Address,
  BaseJobDraftState,
  JobDraftState,
  PublishInEnum,
  Schedule,
} from './JobEditor/context';

import { Maybe } from '@/types';
import {
  AutocompleteCustomersQuery,
  JobInput,
  JobTypeEnum,
  ShiftInput,
  SurgeRate,
  VisibilityStatusEnum,
} from '@/types/graphql';
import { noOp } from '@/util/actions';
import { nTimes, removeAtIndex, replaceAtIndex } from '@/util/array';
import { getFormattedDateInTimeZone, getTimezoneOffset } from '@/util/date';

export type Customer =
  AutocompleteCustomersQuery['agency']['customers']['items'][0];
export type Account = Customer['accounts'][0];

export type OrderType = {
  orderType: Maybe<JobTypeEnum>;
};

export type Billing = {
  account: Account;
  customer: Customer;
};

export type HolidayRate = Pick<SurgeRate, 'id' | 'date' | 'rate'>;

export type OrderState = {
  orderType: Maybe<JobTypeEnum>;
  billing: Maybe<Billing>;
  jobs: JobDraftState[];
  holidays: Maybe<HolidayRate[]>;
};

export enum OrderActionType {
  ADD_JOB = 'add_job',
  CLEAR_JOBS = 'clear_jobs',
  REMOVE_JOB = 'remove_job',
  REPLACE_JOB = 'replace_job',
  SET_BILLING = 'set_billing',
  SET_ORDER_TYPE = 'set_order_type',
  SET_HOLIDAYS = 'set_holidays',
}

type SetBillingAction = {
  type: OrderActionType.SET_BILLING;
  billing: Maybe<Billing>;
};
type SetHolidaysAction = {
  type: OrderActionType.SET_HOLIDAYS;
  holidays: Maybe<HolidayRate[]>;
};
type AddJobAction = { type: OrderActionType.ADD_JOB; job: JobDraftState };
type ClearJobsAction = { type: OrderActionType.CLEAR_JOBS };
type RemoveJobAction = { type: OrderActionType.REMOVE_JOB; index: number };
type ReplaceJobAction = {
  type: OrderActionType.REPLACE_JOB;
  index: number;
  job: JobDraftState;
};
type SetOrderTypeAction = {
  type: OrderActionType.SET_ORDER_TYPE;
  orderType: OrderType;
};
export type OrderAction =
  | AddJobAction
  | ClearJobsAction
  | RemoveJobAction
  | ReplaceJobAction
  | SetBillingAction
  | SetOrderTypeAction
  | SetHolidaysAction;

export type OrderActions = {
  addJob: (job: JobDraftState) => void;
  clearJobs: () => void;
  removeJob: (index: number) => void;
  replaceJob: (index: number, job: JobDraftState) => void;
  setBilling: (billing: Maybe<Billing>) => void;
  setOrderType: (orderType: Maybe<OrderType>) => void;
  setHolidays: (billing: Maybe<HolidayRate[]>) => void;
};

export const OrderStateContext = createContext<OrderState>({
  orderType: null,
  billing: null,
  jobs: [],
  holidays: [],
});

export const OrderActionsContext = createContext<OrderActions>({
  addJob: noOp,
  clearJobs: noOp,
  removeJob: noOp,
  replaceJob: noOp,
  setBilling: noOp,
  setOrderType: noOp,
  setHolidays: noOp,
});

export const useOrderState = () => useContext(OrderStateContext);
export const useOrderActions = () => useContext(OrderActionsContext);
export const stateReducer: Reducer<OrderState, OrderAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case OrderActionType.ADD_JOB: {
      return { ...state, jobs: [...state.jobs, action.job] };
    }

    case OrderActionType.CLEAR_JOBS: {
      return { ...state, jobs: [] };
    }

    case OrderActionType.REMOVE_JOB: {
      return { ...state, jobs: removeAtIndex(state.jobs, action.index) };
    }

    case OrderActionType.REPLACE_JOB: {
      return {
        ...state,
        jobs: replaceAtIndex(state.jobs, action.index, 1, action.job),
      };
    }

    case OrderActionType.SET_BILLING: {
      return { ...state, billing: action.billing };
    }

    case OrderActionType.SET_HOLIDAYS: {
      return { ...state, holidays: action.holidays };
    }

    case OrderActionType.SET_ORDER_TYPE: {
      return { ...state, orderType: action.orderType.orderType };
    }

    default: {
      return state;
    }
  }
};

// === UTILITIES ===

export const costOfSchedule = (schedule: Schedule) => {
  if (!schedule.startTime || !schedule.endTime) return 0;
  const numberOfShifts = scheduleDates(schedule).length;

  const startHour = parse(schedule.startTime, 'HH:mm', new Date());
  const endHour = parse(schedule.endTime, 'HH:mm', new Date());

  const minsPerShift = differenceInMinutes(endHour, startHour);
  const hoursPerShift =
    minsPerShift < 0 ? 24 + minsPerShift / 60 : minsPerShift / 60;

  const totalEstimate = Math.round(
    schedule.costRate * schedule.quantity * numberOfShifts * hoursPerShift,
  );

  return totalEstimate;
};

export const paymentForSchedule = (schedule: Schedule) => {
  if (!schedule.startTime || !schedule.endTime) return 0;
  const numberOfShifts = scheduleDates(schedule).length;

  const startHour = parse(schedule.startTime, 'HH:mm', new Date());
  const endHour = parse(schedule.endTime, 'HH:mm', new Date());

  const minsPerShift = differenceInMinutes(endHour, startHour);
  const hoursPerShift =
    minsPerShift < 0 ? 24 + minsPerShift / 60 : minsPerShift / 60;

  const totalEstimate = Math.round(
    schedule.payRate * schedule.quantity * numberOfShifts * hoursPerShift,
  );

  return totalEstimate;
};

export const scheduleDates = (schedule: Schedule) => {
  const numberOfShifts =
    differenceInCalendarDays(
      schedule.dateRange.endDate!,
      schedule.dateRange.startDate!,
    ) + 1;
  const dates: Date[] = [];

  nTimes(numberOfShifts, (index) => {
    const date = addDays(schedule.dateRange.startDate!, index);
    dates.push(date);
  });

  return dates;
};

export const scheduleToShifts = (
  schedule: Schedule,
  address: Maybe<Address>,
) => {
  const shifts: ShiftInput[] = [];

  scheduleDates(schedule).forEach((date) => {
    const startAt = parse(schedule.startTime, 'HH:mm', date);
    let endAt = parse(schedule.endTime, 'HH:mm', date);
    if (isAfter(startAt, endAt)) {
      endAt = addDays(endAt, 1);
    }
    shifts.push({
      endAt: getFormattedDateInTimeZone(
        endAt,
        address?.timezone!,
      ).toISOString(),
      startAt: getFormattedDateInTimeZone(
        startAt,
        address?.timezone!,
      ).toISOString(),
      timeZoneOffset: getTimezoneOffset(startAt, address?.timezone!),
    });
  });

  return shifts;
};

export const sortSchedules = (schedules: Schedule[]) => {
  schedules.sort((a, b) => {
    const diff =
      parse(a.startTime, 'HH:mm', a.dateRange.startDate!).getTime() -
      parse(b.startTime, 'HH:mm', b.dateRange.startDate!).getTime();
    if (diff === 0) {
      return (
        parse(a.endTime, 'HH:mm', a.dateRange.startDate!).getTime() -
        parse(b.endTime, 'HH:mm', b.dateRange.startDate!).getTime()
      );
    }
    return diff;
  });
  schedules.map((s, index) => (s.groupId = index + 1));
  return schedules;
};

const publishInToMinutes = (publishIn: PublishInEnum) => {
  switch (publishIn) {
    case PublishInEnum.IMMEDIATELY:
      return 0;
    case PublishInEnum.ONE_HOUR:
      return 60;
    case PublishInEnum.SIX_HOURS:
      return 6 * 60;
    case PublishInEnum.TWELVE_HOURS:
      return 12 * 60;
    case PublishInEnum.TWENTY_FOUR_HOURS:
      return 24 * 60;
    case PublishInEnum.FORTY_EIGHT_HOURS:
      return 48 * 60;
    case PublishInEnum.SEVENTY_TWO_HOURS:
      return 72 * 60;
    default:
      return undefined;
  }
};

export const jobStateToJobInput = (
  billing: Billing,
  orderType: JobTypeEnum,
  job: BaseJobDraftState,
  index: number,
) => {
  const base = {
    addressId: job.address!.id,
    contactId: job.contact!.id,
    rateId: job.rateId,
    skillId: job.skill!.id,
    uniformId: job.uniform!.id,
    instructions:
      // we need to send it in null so from the mobile app we show the instructions from skill.descriptionEn/Es
      job.instructions === job.skill?.descriptionEn ? null : job.instructions,
    addressInstructions: job.addressInstructions,
    contactInstructions: job.contactInstructions,
    uniformInstructions: job.uniformInstructions,
    publishIn: publishInToMinutes(job.publishIn),
    jobType: orderType,
    publish: job.publishJob,
    visibility:
      job.postSetting === 'my_selections'
        ? VisibilityStatusEnum.PRIVATE
        : VisibilityStatusEnum.PUBLIC,
    certificates: job.certificates.map((c) => c.id),
  };

  const inputs: JobInput[] = job.schedules.map((schedule, idx) => ({
    ...base,
    quantity: schedule.quantity,
    payRate: schedule.payRate,
    costRate: schedule.costRate,
    shifts: scheduleToShifts(schedule, job.address),
    allShiftsSameWorker: schedule.allShiftsSameWorker,
    groupId: schedule.allShiftsSameWorker ? index : index + idx,
    isHoliday: schedule.isHoliday,
    mandatoryBreakTime: schedule.mandatoryBreakTime,
    originalPayRate: schedule.originalPayRate,
    originalCostRate: schedule.originalCostRate,
    inviteWorkers: schedule.selectedWorkers?.map((sw) => sw.id),
    hireWorkers: schedule.hiredWorkers?.map((sw) => sw.id),
  }));

  return inputs;
};
