import PaymentCard from '../PaymentCard';

import AddressSection from './AddressSection';
import ContactSection from './ContactSection';
import EmployeeSection from './EmployeeSection';
import JobHistory from './JobHistory';
import JobInstructionSection from './JobInstructionSection';
import OrderSection from './OrderSection';
import UniformSection from './UniformSection';
import JobBadges from './JobBadges';

import Card from '@/components/Card';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';
import { GetJobQuery } from '@/types/graphql';
import './index.css';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';

type Props = {
  job: GetJobQuery['job'];
};
const ScrollBar = styled('div', {
  variants: {
    largeTitle: {
      true: {
        width: '337px',
        height: '295px',
        paddingBottom: '50px',
        overflowY: 'scroll',
        WebkitMaskImage:
          'linear-gradient(to bottom, black 80%, transparent 100%)',
        '@phoneOnly': {
          width: '100%',
          height: 'auto',
        },
      },
    },
  },
});
const DetailsCard = ({ job }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const { currentAdminIsCustomerAdmin } = useAuth();
  const cardStyle = {
    width: phoneOnly ? '100%' : '337px',
    height: phoneOnly ? 'auto' : '295px',
    paddingBottom: phoneOnly ? 14 : null,
  };
  const { account } = job;
  const workerBadge = useFeatureValue(FEATURE_TOGGLE.WorkerBadge, false);
  return (
    <>
      <Card
        changeStyle={{ ...cardStyle, overflow: 'auto', paddingBottom: '5px' }}
        noRadius={phoneOnly}
      >
        <ScrollBar largeTitle={account?.customer?.name?.length > 50}>
          <OrderSection
            currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
            job={job}
          />
          <EmployeeSection job={job} />
          <AddressSection job={job} style={{ padding: '0px 20px 10px 20px' }} />
        </ScrollBar>
      </Card>
      <Card changeStyle={cardStyle} noRadius={phoneOnly}>
        <ContactSection job={job} />
      </Card>
      <Card changeStyle={cardStyle} noRadius={phoneOnly}>
        <JobInstructionSection job={job} />
      </Card>
      <Card changeStyle={cardStyle} noRadius={phoneOnly}>
        <UniformSection job={job} />
      </Card>
      <PaymentCard job={job} />
      <Card changeStyle={cardStyle} noRadius={phoneOnly}>
        <JobHistory job={job} />
      </Card>
      {workerBadge && !currentAdminIsCustomerAdmin && (
        <Card changeStyle={cardStyle} noRadius={phoneOnly}>
          <JobBadges job={job} />
        </Card>
      )}
    </>
  );
};

export default DetailsCard;
