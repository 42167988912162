import { CloseOutlined } from '@mui/icons-material';
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import TextInput from '../TextInput';

import ShiftsList from './ShiftsList';

import { GAEvent } from '@/constants/gaevents';
import { WORKERS_LISTING_LIMIT } from '@/constants/general';
import { V3Endpoints } from '@/constants/urls';
import Table from '@/elements/Table';
import Text from '@/elements/Text';
import { useJobHireWorkerMutation, useJobOfferCreateMutation } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import useMediaQuery from '@/hooks/useMediaQuery';
import StaffingTabs from '@/routes/Agency/CreateGigOrder/JobEditor/Steps/StaffingStep/Common/StaffingTabs';
import {
  NotFoundAlert,
  getTabValue,
} from '@/routes/Agency/CreateGigOrder/JobEditor/Steps/StaffingStep/Common/StaffingUtils';
import StaffingWorkerProfileRow from '@/routes/Agency/CreateGigOrder/JobEditor/Steps/StaffingStep/Common/StaffingWorkerProfileRow';
import { JOB_HIRE_AND_OFFER_ERROR_MAP } from '@/routes/Agency/Order/InviteAndHireModal';
import { IconColor } from '@/styles/colors';
import { Job, JobTypeEnum, JobWorkerStatusEnum } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import axiosClient from '@/util/axios/axiosClient';
import { handleMutationFormError } from '@/util/error';
import { getHiredWorkersByJobWorkers } from '@/util/job';
import { workerName } from '@/util/worker';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';

type Props = {
  hideModal: (refetch?: boolean) => void;
  selectedJobs: Record<string, any>[];
  customerId: string;
  jobTaxType: string;
  showShiftList?: boolean;
  timezone?: string;
};

const WorkerStaffingModel = ({
  selectedJobs,
  hideModal,
  customerId,
  jobTaxType,
  showShiftList = true,
  timezone,
}: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const phoneStyles = phoneOnly
    ? { height: '90vh', width: '100%', borderRadius: '20px' }
    : {};

  const [tab, setTab] = useState(0);
  const [workersList, setWorkersList] = useState([]);
  const [search, setSearch] = useState('');
  const debSearch = useDebouncedValue(search, 400);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { currentAdminIsCustomerAdmin: isClientAdmin } = useAuth();
  const containerRef = useRef(null);
  const { logEvent } = useAnalytics();
  const workerBadge = useFeatureValue(FEATURE_TOGGLE.WorkerBadge, false);

  const staffing = useRef(false);
  const [jobs, setJobs] = useState(selectedJobs);
  const [errors, setErrors] = useState([]);
  const [isStaffing, setIsStaffing] = useState(false);
  const [createJobOffer] = useJobOfferCreateMutation({
    refetchQueries: ['GetOrder'],
  });
  const [hireWorker] = useJobHireWorkerMutation({
    refetchQueries: ['GetOrder'],
  });
  const jobInfo = jobs[0] as Job;
  const isLTA = jobInfo.jobType === JobTypeEnum.LTA;
  const selectedIds = jobs
    .filter((j) => j.hiredWorkersCount < j.quantity)
    .map((j) => j.id);

  useEffect(() => {
    if (debSearch) {
      logEvent(GAEvent.WorkerListSearch);
    }
    fetchWorkers(1);
  }, [tab, debSearch]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleScroll = () => {
        if (
          container.scrollTop + container.clientHeight >=
            container.scrollHeight - 1 &&
          hasMore &&
          !loading
        ) {
          if (workersList.length >= WORKERS_LISTING_LIMIT) {
            fetchWorkers(page + 1);
            setPage((prev) => prev + 1);
          }
        }
      };
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [hasMore, loading, debSearch]);

  const fetchWorkers = async (page) => {
    setLoading(true);
    try {
      const res = await axiosClient.post(V3Endpoints.GET_WORKERS_LIST, {
        skillId: Number(jobInfo.skill?.id),
        customerId: Number(customerId),
        addressId: Number(jobInfo.address.id),
        page,
        perPage: WORKERS_LISTING_LIMIT,
        search: debSearch,
        tab: getTabValue(tab, !!jobInfo.skill?.gravyTrained),
        workerType: isLTA ? 'lta' : 'gig',
        taxType: jobTaxType || 'all',
        badgeIds:
          workerBadge && jobInfo.certificates.length > 0
            ? jobInfo.certificates.map((c) => c.id).join(',')
            : null,
      });
      const respData = res?.data?.data || [];
      setWorkersList((prev) => (page > 1 ? [...prev, ...respData] : respData));
      setHasMore(respData.length === WORKERS_LISTING_LIMIT);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const staffingHandler = async (worker, mode, action = 'hire') => {
    if (mode === 'remove') return;
    const workerId = worker.id;
    setErrors([]);
    const checkProperty = action === 'invite' ? 'jobOffers' : 'jobWorkers';
    setIsStaffing(true);
    for (const job of jobs) {
      if (
        !selectedIds.includes(job.id) ||
        job[checkProperty].some(
          (w) =>
            w.worker.id === workerId.toString() &&
            w.status !== JobWorkerStatusEnum.DISMISSED &&
            w.status !== JobWorkerStatusEnum.DROPPED &&
            !w.rejectedAt,
        )
      )
        continue;
      try {
        if (action === 'invite') {
          await handleInvite(job, workerId);
        } else {
          await handleHire(job, workerId);
        }
      } catch (err) {
        handleMutationError(err, worker);
      }
    }
    setIsStaffing(false);
    staffing.current = true;
  };

  const handleHire = async (job, workerId) => {
    const resp = await hireWorker({
      variables: {
        workerId,
        jobId: job.id,
        payRate: null,
        forceHire: !isClientAdmin ? true : null,
      },
    });
    if (resp?.data?.jobHireWorker?.job) {
      logEvent(GAEvent.JobHireWorker, job.id, {
        hiredFrom: extractLocationFromURL(window.location.href),
        tab: getTabValue(tab, !!jobInfo.skill?.gravyTrained),
      });
      updateJobs(resp.data.jobHireWorker.job, workerId);
    }
  };

  const handleInvite = async (job, workerId) => {
    if (!isPastSchedule(job)) {
      const resp = await createJobOffer({
        variables: {
          workerId,
          jobId: job.id,
          forceInvite: !isClientAdmin ? true : null,
        },
      });
      if (resp?.data?.jobOfferCreate?.jobOffer) {
        logEvent(GAEvent.JobInviteWorker, job.id, {
          inviteSentFrom: extractLocationFromURL(window.location.href),
          tab: getTabValue(tab, !!jobInfo.skill?.gravyTrained),
        });
        updateJobs(job, workerId, resp.data.jobOfferCreate.jobOffer);
      }
    }
  };

  const extractLocationFromURL = (url) => {
    if (url.includes('/jobs/')) {
      return 'Job Details Page';
    } else if (url.includes('/orders/')) {
      return 'Order Details Page';
    }
    return 'Unknown';
  };

  const updateJobs = (updatedJob, workerId, jobOffer = null) => {
    setJobs((prevJobs) =>
      prevJobs.map((job) => {
        if (job.id === updatedJob.id) {
          return {
            ...job,
            hiredWorkersCount: updatedJob.hiredWorkersCount,
            jobWorkers: getHiredWorkersByJobWorkers(updatedJob.jobWorkers),
            jobOffers: jobOffer
              ? [...job.jobOffers, jobOffer]
              : job.jobOffers.filter(
                  (j) => j.worker.id !== workerId.toString(),
                ),
          };
        }
        return job;
      }),
    );
  };

  const handleMutationError = (err, worker) => {
    handleMutationFormError(err, {
      setFormError: (key, value) => {
        setErrors([
          {
            key,
            title: value?.title,
            msg: value?.message.replace('{0}', workerName(worker)),
          },
        ]);
      },
      errorMap: JOB_HIRE_AND_OFFER_ERROR_MAP,
    });
  };

  const checkWorkerHiredOrInvited = (workerId, action = '') => {
    const checkProperty = action === 'hire' ? 'jobWorkers' : 'jobOffers';
    return jobs.every((job) =>
      job[checkProperty].some(
        (w) =>
          w.worker.id === workerId.toString() &&
          w.status !== JobWorkerStatusEnum.DISMISSED &&
          w.status !== JobWorkerStatusEnum.DROPPED &&
          !w.rejectedAt,
      ),
    );
  };

  const handleClose = () => hideModal(staffing.current);

  const isPastSchedule = (schedule) =>
    new Date(schedule.firstShiftStartAt).getTime() < new Date().getTime();

  const allSelectedSchedulesArePast = () => jobs.every(isPastSchedule);
  return (
    <Dialog
      PaperProps={{
        id: 'worker-staffing-model',
        style: {
          height: '92vh',
          width: '1100px',
          overflowX: 'hidden',
          borderRadius: '20px',
          maxHeight: 'calc(100% - 40px)',
          ...phoneStyles,
        },
      }}
      fullScreen={phoneOnly}
      maxWidth={'lg'}
      open={true}
      sx={{
        '& .MuiDialog-container': phoneOnly
          ? { alignItems: 'end', marginBottom: '2px' }
          : {},
      }}
      onClose={handleClose}
    >
      <DialogTitle style={{ padding: '10px 22px' }}>
        <Text
          size={'lg'}
          weight={'semibold'}
        >{`Staff Workers for ${jobInfo.skill.name}`}</Text>
        <IconButton
          aria-label="close"
          sx={{ float: 'right', padding: '2px', color: IconColor }}
          onClick={handleClose}
        >
          <CloseOutlined />
        </IconButton>
        {errors.length > 0 && (
          <Alert
            severity="warning"
            style={{ alignItems: 'center' }}
            onClose={() => setErrors([])}
          >
            <ul>
              {errors.map((e) => (
                <>
                  <li key={e.key} style={{ fontWeight: 'bold' }}>
                    {e.title}
                  </li>
                  <li key={e.key}>{e.msg}</li>
                </>
              ))}
            </ul>
          </Alert>
        )}
      </DialogTitle>
      <DialogContent
        ref={containerRef}
        id="worker-list"
        style={{ padding: '22px', paddingTop: '0px' }}
      >
        {showShiftList && (
          <ShiftsList
            isClientAdmin={isClientAdmin}
            isLTA={isLTA}
            jobs={jobs}
            phoneOnly={phoneOnly}
            selectedIds={selectedIds}
            timezone={timezone}
          />
        )}
        {isStaffing && <LinearProgress sx={{ height: '2px' }} />}
        <Divider sx={{ mt: '12px', mb: '8px' }} />
        {!isLTA && (
          <StaffingTabs
            addressId={jobInfo.address.id}
            custId={customerId}
            isLTA={isLTA}
            jobTaxType={jobTaxType}
            setTab={(tab) => {
              setSearch('');
              setTab(tab);
            }}
            skill={jobInfo.skill}
            tab={tab}
            badgeIds={
              workerBadge && jobInfo.certificates.length > 0
                ? jobInfo.certificates.map((c) => c.id).join(',')
                : null
            }
          />
        )}
        <div style={{ width: '100%', marginTop: '12px' }}>
          <TextInput
            id="search-GravyWorkers"
            placeholder="Search for GravyWorkers"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div style={{ width: '100%', marginTop: '18px' }}>
          {loading && <LinearProgress />}
          <Table
            css={phoneOnly ? { tableLayout: 'fixed', overflowX: 'clip' } : {}}
          >
            <tbody>
              {workersList.map((w) => (
                <StaffingWorkerProfileRow
                  key={w.id}
                  buttonsDisableCheck={selectedIds.length === 0 || isStaffing}
                  hireButtonClick={staffingHandler}
                  inviteButtonClick={(worker, mode) =>
                    staffingHandler(worker, mode, 'invite')
                  }
                  inviteButtonDisable={
                    selectedIds.length === 0 ||
                    allSelectedSchedulesArePast() ||
                    isStaffing
                  }
                  isClientAdmin={isClientAdmin}
                  isHiredButton={checkWorkerHiredOrInvited(w.id, 'hire')}
                  isInvitedButton={checkWorkerHiredOrInvited(w.id)}
                  isLTA={isLTA || allSelectedSchedulesArePast()}
                  modalView={true}
                  skillName={jobInfo?.skill?.name}
                  worker={w}
                />
              ))}
            </tbody>
          </Table>
          {!loading && workersList.length === 0 && <NotFoundAlert tab={tab} />}
          {loading && workersList.length >= WORKERS_LISTING_LIMIT && (
            <LinearProgress />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WorkerStaffingModel;
