import { useState, useMemo, useCallback } from 'react';
import {
  GetJobQuery,
  ListAgencyCertificateQuery,
  Scalars,
} from '@/types/graphql';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Autocomplete from '@/components/Autocomplete';
import FormElement from '@/components/FormElement';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import {
  useAdminEditCertificatesOfJobMutation,
  useListAgencyCertificateQuery,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import { CertificateTypeEnum } from '@/types/graphql';
import { sortBy } from '@/util/array';
import Stack from '@/components/Stack';
import Tag from '@/components/Tag';
import Button from '@/components/Button';
import useAnalytics from '@/util/analytics';
import { GAEvent } from '@/constants/gaevents';
import { UNKNOWN_ERROR_TEXT } from '@/constants/text';
import { Maybe } from '@/types/graphql';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import Alert from '@/components/Alert';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export type Props = {
  job: GetJobQuery['job'];
  hideModal: () => Scalars['Void'];
};

type CertItem = ListAgencyCertificateQuery['agency']['certificates'][0];
type ErrorTypes = {
  message: Scalars['String'];
};

const UpdateJobBadgesModal = ({ job, hideModal }: Props) => {
  const [badges, setBadges] = useState(job.certificates || []);
  const { logEvent } = useAnalytics();
  const [certQuery, setCertQuery] = useState('');
  const debouncedCertQuery = useDebouncedValue(certQuery);

  const { currentAgency, currentAdminIsCustomerAdmin } = useAuth();

  const { data: agencyCertificates } = useListAgencyCertificateQuery({
    variables: {
      agencyId: currentAgency!.id,
    },
  });

  const [error, setError] = useState<Maybe<ErrorTypes>>();

  const badgeItems: CertItem[] = useMemo(() => {
    if (agencyCertificates) {
      const filterCertificate = agencyCertificates.agency.certificates.filter(
        (cert) => {
          if (
            cert.certificateType === CertificateTypeEnum.CERTIFICATE ||
            (badges.length > 0 && badges.some((badge) => badge.id === cert.id))
          ) {
            return false;
          }

          return cert.name
            .toLowerCase()
            .includes(debouncedCertQuery.toLowerCase());
        },
      );

      return sortBy(filterCertificate, 'name');
    }
    return [];
  }, [agencyCertificates, badges, debouncedCertQuery]);

  const handleCertRemove = (id: string) => {
    setBadges(badges.filter((badge) => badge.id !== id));
  };

  const handleSelectCertificate = useCallback(
    (item) => {
      setBadges([...badges, item.selectedItem]);
    },
    [badges],
  );

  const [updateBadges, { loading: isLoading }] =
    useAdminEditCertificatesOfJobMutation({
      update: (cache) => {
        cache.modify({
          id: cache.identify(job),
          fields: {
            certificates() {},
          },
        });
      },
    });

  const handleSave = useCallback(async () => {
    if (job.certificates.length > badges.length) {
      logEvent(GAEvent.BadgeRemovedAfterJobCreation);
    } else if (job.certificates.length < badges.length) {
      logEvent(GAEvent.BadgeAddedAfterJobCreation);
    }
    try {
      await updateBadges({
        variables: {
          jobId: job.id,
          certificateIds: badges.map((badge) => badge.id),
        },
      });

      hideModal();
    } catch (err) {
      if (err instanceof GraphQLError || err instanceof ApolloError) {
        return setError({ message: err.message ?? UNKNOWN_ERROR_TEXT });
      }
      setError({ message: UNKNOWN_ERROR_TEXT });
    }
  }, [badges, job.id, updateBadges, hideModal]);

  return (
    <Modal
      disableClickout
      size="sm"
      title="Edit Badge Requirements"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            description={error.message}
            icon={faExclamationTriangle}
            status="warning"
          />
        )}
        <FormElement>
          <Autocomplete
            fixedSelectHeight="auto"
            id="certificate"
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? item.name : '')}
            items={badgeItems}
            placeholder="Select Badges"
            selectedItem={null}
            onInputValueChange={({ inputValue }) =>
              setCertQuery(inputValue || '')
            }
            onSelectedItemChange={handleSelectCertificate}
          />

          {badges.length > 0 && (
            <Stack style={{ marginTop: '15px' }} vertical>
              {badges.map((badge) => (
                <Tag
                  key={badge.id}
                  label={badge.name}
                  icon={badge.imageUrl}
                  showIcon={true}
                  style={{ backgroundColor: '#e0eaff' }}
                  onRemove={() => handleCertRemove(badge.id)}
                />
              ))}
            </Stack>
          )}
        </FormElement>
        <Stack justify="end">
          <Button
            a11yLabel="Submit form"
            isLoading={isLoading}
            label="Save"
            type="submit"
            onClick={handleSave}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default UpdateJobBadgesModal;
