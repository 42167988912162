import Card from '@/components/Card';
import Stack from '@/components/Stack';
import StackContainer from '@/components/StackContainer';
import { GetJobQuery, JobStatusEnum } from '@/types/graphql';
import Tag from '@/components/Tag';
import useModal from '@area2k/use-modal';
import UpdateJobBadgesModal from './UpdateJobBadgesModal';

type Props = {
  job: GetJobQuery['job'];
};

const JobBadges = ({ job }: Props) => {
  const badges = job.certificates || [];

  const [showUpdateModal, hideUpdateModal] = useModal(
    () => <UpdateJobBadgesModal hideModal={hideUpdateModal} job={job} />,
    [job],
  );
  return (
    <Card.Section
      actions={
        job.status === JobStatusEnum.UPCOMING
          ? [
              {
                a11yLabel: 'Edit the job badges',
                label: 'Edit',
                onAction: showUpdateModal,
                id: 'edit-job-badges',
              },
            ]
          : undefined
      }
      styleHeading={true}
      title="Badge Requirements"
      titleStyle={{ whiteSpace: 'nowrap' }}
    >
      <StackContainer className="scrollbar">
        {badges.length > 0 && (
          <Stack style={{ marginTop: '10px' }} vertical>
            {badges.map((badge) => (
              <Tag
                key={badge.id}
                label={badge.name}
                icon={badge.imageUrl}
                showIcon={true}
                style={{ backgroundColor: '#e0eaff' }}
              />
            ))}
          </Stack>
        )}
      </StackContainer>
    </Card.Section>
  );
};

export default JobBadges;
