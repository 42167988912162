import { useEffect } from 'react';
import AgencyChat from '@/routes/Agency/Chat';
import { currentAdminVar } from '@/util/apollo/cache';
import { useReactiveVar } from '@apollo/client';
import { Role } from '@/routes/PrivateRoute';
import { updateUrlParams } from '@/util/dashboard';
import { Navigate, useLocation } from 'react-router-dom';
import routes from '@/routes/routes';

const Chat = () => {
  const location = useLocation();
  const currentAdmin = useReactiveVar(currentAdminVar);
  useEffect(() => {
    console.log('HubSpot chat widget reinitialized for /chat route');
    if (currentAdmin!.role === Role.CUSTOMER_ADMIN) {
      updateUrlParams({
        userId: currentAdmin!.user.id,
        clientId: currentAdmin!.customer.id,
        email: currentAdmin!.user.email,
        firstName: currentAdmin!.user.firstName,
        lastName: currentAdmin!.user.lastName,
      });
    }
  }, []);

  if (currentAdmin!.role === Role.CUSTOMER_ADMIN) {
    return <AgencyChat />;
  } else {
    let clientId = '';
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('clientId')) {
      clientId = queryParams.get('clientId')!;
    }

    return (
      <Navigate
        to={`/${routes.clients}/${clientId}`}
        state={{ from: location }}
      />
    );
  }
};

export default Chat;
