import useModal from '@area2k/use-modal';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import {
  costOfSchedule,
  jobStateToJobInput,
  sortSchedules,
  useOrderActions,
  useOrderState,
} from '../../../context';
import {
  JobDraftState,
  Schedule,
  Step,
  useJobDraftActions,
} from '../../context';
import BottomBar from '../BottomBar';
import Layout from '../Layout';
import { FirstOrderNotice } from '../ScheduleStep';
import { getTotalHoursInDecimal } from '../ScheduleStep/util';
import SummaryTable from '../SummaryTable';

import JobCardDetailsRevamped from './JobCardDetailsRevamped';
import Summary from './Summary';
import PostSubmitModal from './modals/PostSubmitModal';
import RemoveJobFromOrderModal from './modals/RemoveJobModal';

import { SubmitErrorAlert } from '@/components/Alerts';
import type { SubmitErrorType } from '@/components/Alerts/SubmitErrorAlert/types';
import Avatar from '@/components/Avatar';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import { Subheading, Title } from '@/components/Typography';
import { GAEvent } from '@/constants/gaevents';
import { useCreateGigOrderMutation } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import PaymentSection from '@/routes/Agency/Account/PaymentSection';
import { neutral } from '@/styles/colors';
import { AccountRateTypeEnum, JobTypeEnum, Scalars } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import { calculateTotalHours, formatDuration } from '@/util/datetime';
import { handleGraphQLError } from '@/util/error';
import { centsToCurrency } from '@/util/number';
import { isIOS, isWeb } from '@/util/platform';

const calculateTotalHoursByJobs = (jobs: JobDraftState[]) => {
  return jobs.reduce((acc, jobElement) => {
    const calculatedHours = calculateTotalHours({
      schedules: jobElement.schedules,
    });

    if (calculatedHours) {
      return acc + calculatedHours;
    }

    return acc;
  }, 0);
};

const calculateTotalEstimateByJobs = (jobs: JobDraftState[]) => {
  return jobs.reduce((acc, jobElement) => {
    return (
      acc +
      jobElement.schedules.reduce((sacc, sel) => sacc + costOfSchedule(sel), 0)
    );
  }, 0);
};

type Props = {
  setStep: (step: Step) => Scalars['Void'];
  onClose: () => Scalars['Void'];
};

const ReviewStep = ({ setStep, onClose }: Props) => {
  const { currentAgency } = useAuth();
  const [submitError, setSubmitError] = useState<SubmitErrorType | null>(null);

  const { billing, jobs, orderType } = useOrderState();
  const { updateJob } = useJobDraftActions();
  const { removeJob, replaceJob } = useOrderActions();
  const { resetState } = useJobDraftActions();

  const startTime = new Date().getTime();

  const { logEvent } = useAnalytics();

  const showMarkupBasedPricing =
    billing?.account.rateType === AccountRateTypeEnum.MARKUP;

  const { clearJobs, setBilling } = useOrderActions();
  const [createOrder, { data, loading }] = useCreateGigOrderMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: {
          orders() {},
          shiftsByWeek() {},
        },
      });
    },
    onError(error) {
      handleGraphQLError(error, {
        all: (gqlError) => {
          return setSubmitError({ message: gqlError.message });
        },
      });
    },
  });
  const [showPostModal, hidePostModal] = useModal(
    () => (
      <PostSubmitModal
        hideModal={() => {
          hidePostModal();
          clearJobs();
          setBilling(null);
        }}
        hireMessage={data?.orderCreate?.workersHired}
        order={data!.orderCreate.order}
      />
    ),
    [data],
  );

  const handleAddNewSkill = () => {
    resetState();
    setStep(Step.SKILL);
  };

  const handleSubmit = async () => {
    const allJobs = [...jobs];
    let index = 1;
    const jobsParsed = allJobs.flatMap((job) => {
      const jobs = jobStateToJobInput(billing!, orderType!, job, index);
      if (jobs && jobs.length) {
        index = jobs[jobs.length - 1].groupId! + 1;
      }
      return jobs;
    });

    if (jobsParsed[0].certificates && jobsParsed[0].certificates.length > 0) {
      logEvent(GAEvent.BadgeAddedOnJobCreation);
    }
    await createOrder({
      variables: {
        accountId: billing!.account.id,
        jobs: jobsParsed,
      },
    });
  };

  useEffect(() => {
    if (data) {
      if (billing?.account.rateType === AccountRateTypeEnum.MARKUP) {
        logEvent(
          orderType === JobTypeEnum.LTA
            ? GAEvent.StepSixMarkupLta
            : GAEvent.StepSixMarkupGig,
          billing?.account?.id,
          {
            time_spent: (new Date().getTime() - startTime) / 1000,
            platform: isWeb() ? 'web' : isIOS() ? 'ios' : 'android',
          },
        );
      } else {
        logEvent(
          orderType === JobTypeEnum.LTA
            ? GAEvent.StepFiveStaticLta
            : GAEvent.StepFiveStaticGig,
          data?.orderCreate?.order?.id,
          {
            time_spent: (new Date().getTime() - startTime) / 1000,
            platform: isWeb() ? 'web' : isIOS() ? 'ios' : 'android',
          },
        );
      }
      showPostModal();
    }
  }, [data, showPostModal]);

  const totalHours = formatDuration(calculateTotalHoursByJobs(jobs));
  const totalDecimalHours = getTotalHoursInDecimal(
    calculateTotalHoursByJobs(jobs),
  );
  const totalEstimate = calculateTotalEstimateByJobs(jobs);
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const paddings = phoneOnly ? { paddingLeft: 0, paddingRight: 0 } : {};

  useEffect(() => {
    if (jobs.length === 0) onClose();
  }, [jobs]);

  const removeSkillJob = (index) => {
    removeJob(index);
  };

  const updateSchedule = (jobIndex, schedules: Schedule[]) => {
    const job = jobs[jobIndex];
    if (schedules.length) {
      job.schedules = sortSchedules(schedules);
      replaceJob(jobIndex, job);
    } else {
      removeJob(jobIndex);
    }
  };

  const [showRemoveJobModal, hideRemoveJobModal] = useModal(
    ({ index }: { index: number }) => (
      <RemoveJobFromOrderModal
        hideModal={hideRemoveJobModal}
        onConfirm={() => {
          removeSkillJob(index);
          hideRemoveJobModal();
        }}
      />
    ),
    [jobs],
  );
  const disableSubmit = jobs.some((job) => job.payRate! < job.minPay!);

  return (
    <Layout
      css={{
        backgroundColor: showMarkupBasedPricing
          ? 'white'
          : neutral.neutralGhost,
        ...paddings,
      }}
    >
      {submitError && <SubmitErrorAlert description={submitError.message} />}
      <Stack vertical align="center" gap={24}>
        <Grid container spacing={2.5}>
          <Grid item md={1.5} sm={0} />
          <Grid item lg={4.5} md={6} xs={12}>
            <Title
              css={{
                paddingBottom: 24,
                paddingLeft: phoneOnly ? 24 : 0,
              }}
            >{`Order for ${billing!.customer.name}`}</Title>
            <Stack vertical gap={32}>
              {jobs.map((job, index) => {
                return (
                  <>
                    {showMarkupBasedPricing ? (
                      <JobCardDetailsRevamped index={index} job={job} />
                    ) : (
                      <Card key={index} noRadius={phoneOnly}>
                        <Card.Section>
                          <Stack>
                            <Stack gap={8}>
                              <Avatar size="md" src={job.skill!.imageUrl!} />
                              <Subheading>{job.skill!.name}</Subheading>
                            </Stack>
                            <Stack
                              justify={'end'}
                              style={{ paddingRight: '10px' }}
                            >
                              <Card
                                actions={[
                                  {
                                    label: 'Remove',
                                    a11yLabel: 'Remove',
                                    onAction: () =>
                                      showRemoveJobModal({ index }),
                                  },
                                ]}
                                changeStyle={{ boxShadow: 'none' }}
                              />
                            </Stack>
                          </Stack>
                        </Card.Section>
                        <Card.Section>
                          <SummaryTable
                            handleJobsChange={(schedules: Schedule[]) => {
                              updateSchedule(index, schedules);
                            }}
                            postSetting={job.postSetting}
                            schedules={job.schedules}
                            skillId={job.skill?.id}
                            address={job.address}
                          />
                        </Card.Section>
                        <Summary billing={billing} job={job} />
                      </Card>
                    )}
                  </>
                );
              })}

              <Button
                a11yLabel="Add other skill to order"
                appearance="outline"
                css={{
                  width: phoneOnly ? 'calc(100% - 24px)' : '100%',
                  backgroundColor: '#EEFFEC',
                  margin: phoneOnly ? 'auto' : undefined,
                }}
                iconLeft={faPlus}
                id="add-skill-btn"
                label="Add Skills"
                type="button"
                onClick={handleAddNewSkill}
              />
            </Stack>
          </Grid>
          <Grid item lg={4} md={3} xs={12}>
            <div style={{ paddingTop: phoneOnly ? 16 : 60 }}>
              <Stack verticalGap={40}>
                <Card
                  headerNobg={true}
                  noRadius={phoneOnly}
                  showBorder={showMarkupBasedPricing ?? false}
                  title="Summary"
                >
                  <Card.Section>
                    <Stack vertical gap={16}>
                      <Stack justify="apart">
                        <Subheading>Total Hours</Subheading>
                        <Subheading>
                          {showMarkupBasedPricing
                            ? totalDecimalHours
                            : totalHours}
                        </Subheading>
                      </Stack>
                      <Stack justify="apart">
                        <Subheading>Total Estimate</Subheading>
                        <Subheading
                          color={
                            showMarkupBasedPricing ? 'themeDefault' : 'theme'
                          }
                        >
                          ${centsToCurrency(totalEstimate)}
                        </Subheading>
                      </Stack>
                    </Stack>
                  </Card.Section>
                </Card>
              </Stack>
              <Stack css={{ marginTop: 18 }} verticalGap={40}>
                <PaymentSection
                  hideChangeButton
                  account={billing!.account}
                  cardBorderOutline={showMarkupBasedPricing ?? false}
                  notice={<FirstOrderNotice billing={billing} />}
                  showMarkupBasedPricing={showMarkupBasedPricing}
                />
              </Stack>
            </div>
          </Grid>
          <Grid item md={1.5} sm={0} />
        </Grid>

        <BottomBar>
          <Button
            a11yLabel="Go back to previous step"
            appearance="outline"
            id="back-btn"
            label="Back"
            type="button"
            onClick={() => {
              const lastjob = jobs[jobs.length - 1];
              if (lastjob) {
                updateJob({ ...lastjob });
              }
              removeJob(jobs.length - 1);
              if (billing?.account.rateType === AccountRateTypeEnum.MARKUP) {
                setStep(Step.PAYRATE);
              } else {
                setStep(Step.DETAILS);
              }
            }}
          />
          <Button
            a11yLabel="Submit Order"
            disabled={disableSubmit}
            id="submit-order-btn"
            isLoading={loading}
            label="Submit order"
            type="button"
            onClick={handleSubmit}
          />
        </BottomBar>
      </Stack>
    </Layout>
  );
};

export default ReviewStep;
