import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router';

interface visitorIdentification {
  token: string;
  email: string;
}
interface HubspotConversationsContextType {
  initChat: (visitorIdentification: visitorIdentification) => void;
  isReady: boolean;

  unreadMessagesCount: number;
}

const HubspotConversationsContext =
  createContext<HubspotConversationsContextType | null>(null);

const HUBSPOT_INLINE_EMBED_ELEMENT_ID =
  'hubspot-conversations-inline-embed-selector';

export const HubspotConversationsProvider = ({ children }) => {
  const location = useLocation();
  const [isReady, setIsReady] = useState(false);

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  // This is for Visitor Identification API

  const initChat = useCallback(
    (visitorIdentification: visitorIdentification) => {
      if (!isReady) return;
      window.hsConversationsSettings = {
        ...window.hsConversationsSettings,
        ...(visitorIdentification.token && {
          identificationEmail: visitorIdentification.email,
          identificationToken: visitorIdentification.token,
        }),
      };
      window.HubSpotConversations.widget.load();
      // window.HubSpotConversations.widget.open(); // Uncomment this line to open the chat widget on page load
    },
    [isReady],
  );

  const onConversationsReady = useCallback(() => {
    setIsReady(true);
  }, []);

  useEffect(() => {
    // Check if the current route is '/chat'
    if (location.pathname === '/chat') {
      window.HubSpotConversations.resetAndReloadWidget();

      const selector = '#' + HUBSPOT_INLINE_EMBED_ELEMENT_ID;
      if (document.querySelector(selector)) {
        // Dynamically add inlineEmbedSelector
        window.hsConversationsSettings = {
          ...window.hsConversationsSettings,
          inlineEmbedSelector: selector,
        };

        // Refresh the HubSpot widget to apply changes
        if (window.HubSpotConversations && window.HubSpotConversations.widget) {
          window.HubSpotConversations.widget.refresh();
          console.log('HubSpot chat widget updated for /chat route');
        } else {
          console.warn('HubSpotConversations widget not initialized yet');
        }
      }

      window.HubSpotConversations.widget.remove();
      console.log('HubSpot chat widget closed for /chat route');

      // Reinitialize the chat widget after a delay to ensure DOM readiness
      setTimeout(() => {
        window.HubSpotConversations.widget.refresh();
        window.HubSpotConversations.widget.open();
        console.log('HubSpot chat widget reinitialized for /chat route');
      });
    } else {
      // Remove the inlineEmbedSelector on other routes
      if (window.hsConversationsSettings?.inlineEmbedSelector) {
        delete window.hsConversationsSettings.inlineEmbedSelector;
        console.log('Removed inlineEmbedSelector for non-/chat route');

        // Refresh the HubSpot widget to remove the changes
        if (window.HubSpotConversations && window.HubSpotConversations.widget) {
          window.HubSpotConversations.widget.refresh();
          window.HubSpotConversations.widget.close();
        }
      }
    }
  }, [location]);

  useEffect(
    function init() {
      if (window.HubSpotConversations) {
        onConversationsReady();
      } else {
        window.hsConversationsOnReady = [onConversationsReady];
      }
    },
    [onConversationsReady],
  );

  useEffect(
    function addEventListeners() {
      if (!isReady) return;

      function listener(payload: { unreadCount: number }) {
        setUnreadMessagesCount(payload.unreadCount);
      }

      window.HubSpotConversations.on(
        'unreadConversationCountChanged',
        listener,
      );

      return () => {
        window.HubSpotConversations.off(
          'unreadConversationCountChanged',
          listener,
        );
      };
    },
    [isReady],
  );

  useEffect(
    function refreshConversationsOnRouteChange() {
      if (!isReady) return;
      window.HubSpotConversations.resetAndReloadWidget();
    },
    [isReady],
  );

  return (
    <HubspotConversationsContext.Provider
      value={{
        initChat,
        unreadMessagesCount,
        isReady,
      }}
    >
      {children}
      <div
        className={'chatWidgetContainer'}
        id={HUBSPOT_INLINE_EMBED_ELEMENT_ID}
      />
    </HubspotConversationsContext.Provider>
  );
};

export function useHubspotConversations() {
  const context = useContext(HubspotConversationsContext);

  if (context === null) {
    throw new Error(
      'useHubspotConversations must be used within HubspotConversationsProvider',
    );
  }

  return context;
}

declare global {
  interface Window {
    hsConversationsSettings: Record<string, any>;
    hsConversationsOnReady: Array<() => void>;
    HubSpotConversations: {
      on: any;
      off: any;
      resetAndReloadWidget: () => void;
      widget: {
        status: () => { loaded: boolean; pending: boolean };
        load: (params?: { widgetOpen: boolean }) => void;
        remove: () => void;
        open: () => void;
        close: () => void;
        refresh: (openToNewThread?: boolean) => void;
      };
    };
  }
}
