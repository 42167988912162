import { faTimes } from '@fortawesome/free-solid-svg-icons';

import logo from '@/assets/img/gravylogo.png';
import Icon from '@/elements/Icon';
import styled from '@/styles';

const Wrapper = styled('span', {
  $$bgColor: '$colors$neutralA12',
  $$textColor: '$colors$textDefault',
  alignItems: 'center',
  display: 'inline-flex',
  minHeight: '2rem',
  maxWidth: '100%',

  backgroundColor: '$$bgColor',
  borderRadius: '$lg',
  color: '$$textColor',
});

const TextWrapper = styled('span', {
  display: 'inline-block',

  overflow: 'hidden',

  fontSize: '$md',
  lineHeight: '22px',
  textOverflow: 'ellipsis',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',

  variants: {
    hasButton: {
      true: {
        paddingRight: '0.5rem',
      },
      false: {
        padding: '0 0.5rem',
      },
    },
  },

  defaultVariants: {
    hasButton: false,
  },
});

const CloseButton = styled('button', {
  $$iconColor: '$colors$textLight',

  alignItems: 'center',
  appearance: 'none',
  boxSize: '1.75rem',
  display: 'inline-flex',
  justifyContent: 'center',

  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '0 $lg $lg 0',
  color: '$$iconColor',
  cursor: 'pointer',
  outline: 'none',

  fontSize: '$lg',

  '&:hover': {
    backgroundColor: '$neutralA8',
  },
  phoneOnly: {
    fontSize: '$sm',
  },

  focusPseudoElement: {
    baseRadius: '0 $lg $lg 0',
    element: 'after',
  },
});
const StyledIcon = styled('img', {
  width: '20px',
  height: '20px',
  variants: {
    hasButton: {
      true: {
        marginRight: '0.5rem',
      },
      false: {
        marginLeft: '0.5rem',
      },
    },
  },
});

export type Props = {
  disabled?: boolean;
  label: string;
  onRemove?: () => void;
  style?: Record<string, any> | undefined;
  showIcon?: boolean;
  icon?: string;
  id?: number;
};

const Tag = ({
  disabled,
  label,
  onRemove,
  style,
  showIcon,
  icon,
  id = 0,
}: Props) => {
  const buttonIsVisible = Boolean(!disabled && onRemove);

  return (
    <Wrapper css={style}>
      {buttonIsVisible && (
        <CloseButton
          data-testid={`close-btn-${id}`}
          disabled={disabled}
          id={`close-btn-${id}`}
          type="button"
          onClick={onRemove}
        >
          <Icon fixedWidth icon={faTimes} />
        </CloseButton>
      )}
      {showIcon && (
        <StyledIcon hasButton={buttonIsVisible} src={icon ? icon : logo} />
      )}
      <TextWrapper hasButton={buttonIsVisible}>{label}</TextWrapper>
    </Wrapper>
  );
};

Wrapper.displayName = 'stitches(Tag.Wrapper)';
TextWrapper.displayName = 'stitches(Tag.TextWrapper)';
CloseButton.displayName = 'stitches(Tag.CloseButton)';

Tag.Wrapper = Wrapper;

export default Tag;
