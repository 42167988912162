import { useEffect } from 'react';

import LoadingState from '@/components/LoadingState';
import { useAuthenticateChatServiceMutation } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import {
  HubspotConversationsProvider,
  useHubspotConversations,
} from '@/hooks/useHubSpotChat';
import { currentAdminVar } from '@/util/apollo/cache';
import { useReactiveVar } from '@apollo/client';
import { Role } from '@/routes/PrivateRoute';

const HubSpotChatEmbedded = () => {
  const { currentAdmin } = useAuth();
  const { initChat, isReady } = useHubspotConversations();
  const [authenticateChatService, { loading }] =
    useAuthenticateChatServiceMutation();

  useEffect(() => {
    if (isReady && currentAdmin?.user.email) {
      authenticateChatService()
        .then(({ data }) => {
          const { chatServiceAuthenticate: token } = data;
          initChat({
            email: currentAdmin.user.email,
            token,
          });
        })
        .catch(() => {});
    }
  }, [isReady, initChat]);

  return !loading && !isReady ? <LoadingState /> : null;
};

const Chat = () => {
  const currentAdmin = useReactiveVar(currentAdminVar);
  return currentAdmin!.role === Role.CUSTOMER_ADMIN ? (
    <HubspotConversationsProvider>
      <HubSpotChatEmbedded />
    </HubspotConversationsProvider>
  ) : null;
};

export default Chat;
