import { SubmitHelpers } from '@area2k/use-form';

import Button from '@/components/Button';
import Stack from '@/components/Stack';
import { MAX_LENGTH_SHIFT_SUPERVISOR_INSTRUCTIONS } from '@/constants/text';
import Form from '@/form';
import TextAreaField from '@/form/TextAreaField';
import { useUpdateJobMutation } from '@/graphql';
import ContactSelectField from '@/routes/Agency/Job/DetailsCard/ContactSection/ContactSelectField';
import {
  getJobValues,
  isJobFieldEditable,
  jobScreenField,
} from '@/routes/Agency/Job/util';
import { Prettify } from '@/types';
import { GetJobQuery, Scalars } from '@/types/graphql';
import { handleMutationFormError } from '@/util/error';

export type Props = {
  job: Prettify<GetJobQuery['job']>;
  hideModal?: () => Scalars['Void'];
};

type FormValues = {
  contact: GetJobQuery['job']['contact'];
  contactInstructions: Scalars['String'];
};

const UpdateContactForm = ({ job, hideModal }: Props) => {
  const initialValues: FormValues = {
    contact: job.contact,
    contactInstructions: job.contactInstructions ?? '',
  };

  const [updateJob, { loading }] = useUpdateJobMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(job),
        fields: {
          contact() {},
          contactInstructions() {},
        },
      });
    },
  });

  const handleSubmit = async (
    values: FormValues,
    { setFormError }: SubmitHelpers,
  ) => {
    const jobValues = getJobValues(job);
    try {
      if (
        values.contact.id === jobValues.contactId &&
        values.contactInstructions === jobValues.contactInstructions
      ) {
        return hideModal?.();
      }

      await updateJob({
        variables: {
          ...jobValues,
          contactId: values.contact.id,
          contactInstructions: values.contactInstructions.trim(),
        },
      });

      hideModal?.();
    } catch (err) {
      handleMutationFormError(err, {
        setFormError,
        errorMap: {
          all: (gqlError) => ({
            title: gqlError.name,
            message: gqlError.message,
            status: 'danger',
          }),
        },
      });
    }
  };

  return (
    <Form
      initialValues={initialValues}
      isLoading={loading}
      onSubmit={handleSubmit}
    >
      <ContactSelectField
        disabled={!isJobFieldEditable(job, jobScreenField.shiftSupervisor)}
        fieldId="contact"
        job={job}
        label="Shift supervisor"
      />
      <TextAreaField
        disabled={
          !isJobFieldEditable(job, jobScreenField.shiftSupervisorInstructions)
        }
        fieldId="contactInstructions"
        label="Shift supervisor instructions"
        maxLength={MAX_LENGTH_SHIFT_SUPERVISOR_INSTRUCTIONS}
        placeholder="Provide any instructions for checking in with the shift supervisor..."
      />
      <Stack justify="end">
        <Button
          a11yLabel="Submit form"
          isLoading={loading}
          label="Update"
          type="submit"
        />
      </Stack>
    </Form>
  );
};

export default UpdateContactForm;
